/* eslint-disable max-lines */
import type {} from '@mui/lab/themeAugmentation';
import { Components, CssVarsTheme, Theme } from '@mui/material';

import { palette } from '../palette';

//
//

export const MuiBreadcrumbs: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
>['MuiBreadcrumbs'] = {
  styleOverrides: {
    root: {
      color: 'inherit',
      fontSize: '0.875rem',

      '.MuiTypography-body1': {
        fontSize: '0.875rem',
        color: 'inherit',
      },

      '.MuiBreadcrumbs-separator': {
        fontSize: '1rem',
        color: palette?.text?.secondary,
      },
    },
  },
};
