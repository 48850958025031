/* eslint-disable max-lines */
import type {} from '@mui/lab/themeAugmentation';
import { Components, CssVarsTheme, SimplePaletteColorOptions, Theme } from '@mui/material';

import { palette } from '../palette';

//
//

const colors = {
  error: {
    color: 'inherit',
    borderColor: '#FDC9D0',
    background: '#FFF3F4',
  },
  warning: {
    color: 'inherit',
    borderColor: '#FAEBA4',
    background: '#FEFDF5',
  },
  info: {
    color: 'inherit',
    borderColor: '#B5EFEF',
    background: '#F4FCFC',
  },
  success: {
    color: 'inherit',
    borderColor: '#CAF4C8',
    background: '#F7FDF7',
  },
};

//

export const MuiAlert: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
>['MuiAlert'] = {
  styleOverrides: {
    root: {
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: 12,
      '&.MuiAlert-colorError': {
        ...colors.error,
        '.MuiAlert-icon': {
          color: (palette!.error as SimplePaletteColorOptions).main,
        },
        '.MuiAlert-action .MuiSvgIcon-fontSizeSmall': {
          fill: '#70192F',
        },
      },
      '&.MuiAlert-colorWarning': {
        ...colors.warning,
        '.MuiAlert-icon': {
          color: (palette!.warning as SimplePaletteColorOptions).dark,
        },
        '.MuiAlert-action .MuiSvgIcon-fontSizeSmall': {
          fill: '#6F673B',
        },
      },
      '&.MuiAlert-colorInfo': {
        ...colors.info,
        '.MuiAlert-icon': {
          color: (palette!.info as SimplePaletteColorOptions).dark,
        },
        '.MuiAlert-action .MuiSvgIcon-fontSizeSmall': {
          fill: '#306666',
        },
      },
      '&.MuiAlert-colorSuccess': {
        ...colors.success,
        '.MuiAlert-icon': {
          color: (palette!.success as SimplePaletteColorOptions).main,
        },
        '.MuiAlert-action .MuiSvgIcon-fontSizeSmall': {
          fill: '#476A44',
        },
      },
    },
  },
};
