import { Components, CssVarsTheme, Theme } from '@mui/material';

//
//

export const MuiTypography: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
>['MuiTypography'] = {
  styleOverrides: {
    gutterBottom: {
      marginBottom: 6,
      variants: [
        {
          props: { variant: 'body2' },
          style: { marginBottom: 5 },
        },
        {
          props: { variant: 'overline' },
          style: { marginBottom: 4 },
        },
        {
          props: { variant: 'h5' },
          style: { marginBottom: 8 },
        },
        {
          props: { variant: 'h4' },
          style: { marginBottom: 12 },
        },
        {
          props: { variant: 'h3' },
          style: { marginBottom: 17 },
        },
        {
          props: { variant: 'h1' },
          style: { marginBottom: 34 },
        },
      ],
    },
  },
};
