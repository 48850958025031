import type {} from '@mui/lab/themeAugmentation';
import { Components, CssVarsTheme, Theme } from '@mui/material';

export const MuiInputLabel: Components<Theme & CssVarsTheme>['MuiInputLabel'] = {
  defaultProps: {
    shrink: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.caption,
      letterSpacing: '0.15px',
    }),
  },
};
