/* eslint-disable max-lines */
import type {} from '@mui/lab/themeAugmentation';
import { Components, CssVarsTheme, Theme } from '@mui/material';

//
//

export const MuiRadioGroup: Components<Theme & CssVarsTheme>['MuiRadioGroup'] = {
  styleOverrides: {
    root: () => ({}),
  },
};
