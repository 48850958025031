import type {} from '@mui/lab/themeAugmentation';
import { Components, CssVarsTheme, Theme } from '@mui/material';

import { MuiAlert } from './alert';
import { MuiAppBar } from './app-bar';
import { MuiBreadcrumbs } from './breadcrumbs';
import { MuiButton } from './button';
import { MuiChip } from './chip';
import { MuiDataGrid } from './data-grid';
import { MuiInputAdornment } from './input-adornment';
import { MuiInputBase } from './input-base';
import { MuiInputLabel } from './input-label';
import { MuiMenu } from './menu';
import { MuiPaper } from './paper';
import { MuiRadio } from './radio';
import { MuiRadioGroup } from './radio-group';
import { MuiSelect } from './select';
import { MuiTextField } from './text-field';
import { MuiTypography } from './typography';
// import { MuiSwitch } from './switch';

//
//

export const components: Components<Omit<Theme, 'components' | 'palette'> & CssVarsTheme> = {
  // MuiSwitch,
  MuiButton,
  MuiRadio,
  MuiAlert,
  MuiAppBar,
  MuiInputAdornment,
  MuiRadioGroup,
  MuiBreadcrumbs,
  MuiTypography,
  MuiChip,
  MuiTextField,
  MuiMenu,
  MuiSelect,
  MuiInputLabel,
  MuiInputBase,

  // MuiTextField: {
  //   styleOverrides: {
  //     root: {
  //       '& .MuiFilledInput-root': {
  //         backgroundColor: '#ffffff',
  //         '&:hover': { backgroundColor: '#ffffff' },
  //         '&.Mui-focused': { backgroundColor: '#ffffff' },
  //         '&:active': { backgroundColor: '#ffffff' },
  //       },
  //       '& label.Mui-focused': { color: 'main' },
  //     },
  //   },
  // },
  // MuiInputLabel: {
  //   styleOverrides: {
  //     outlined: {
  //       zIndex: 1,
  //       pointerEvents: 'none',
  //       transform: 'translate(14px, 16px) scale(1)',
  //       maxWidth: 'calc(100% - 24px)',
  //     },
  //   },
  // },
  MuiPaper,
  // @ts-expect-error TODO: fix typing
  MuiDataGrid,
  MuiFilledInput: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        '&::before, &::after': {
          borderBottom: 0,
        },
        '&:hover:not(.Mui-disabled, .Mui-error):before': {
          borderBottom: 0,
        },
        '&.Mui-focused:after': {
          borderBottom: 0,
        },
      },
    },
  },
};
