import type {} from '@mui/lab/themeAugmentation';
import { ThemeOptions } from '@mui/material';

//
//

export const shadows: ThemeOptions['shadows'] = [
  'none',
  '0px 1px 2px 0px rgba(16, 24, 40, 0.05);',
  '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 1px 3px 0px rgba(16, 24, 40, 0.05);',
  '0px 2px 4px -2px rgba(16, 24, 40, 0.05), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);',
  '0px 2px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);',
  '0px 3px 5px -1px rgba(0, 0, 0, 0.20), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);',
  '0px 3px 5px -1px rgba(0, 0, 0, 0.20), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);',
  '0px 4px 5px -2px rgba(0, 0, 0, 0.20), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);',
] as unknown as ThemeOptions['shadows'];
