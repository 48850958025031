import { Components, CssVarsTheme, SimplePaletteColorOptions, Theme } from '@mui/material';

import { palette } from '../palette';
import { shadows } from '../shadows';

//
//

const primaryPalette = palette?.primary as SimplePaletteColorOptions;

export const MuiAppBar: Components<
  Omit<Theme, 'components' | 'palette'> & CssVarsTheme
>['MuiAppBar'] = {
  styleOverrides: {
    root: {
      boxShadow: shadows?.[4],
      input: {
        fontSize: 14,
        '&:-webkit-autofill': {
          transitionDelay: '9999s !important',
        },
      },
      '& .MuiFormControl-root': {
        maxWidth: 564,
      },
      '& .MuiSvgIcon-root': {
        width: 20,
        height: 20,
        color: palette?.icon.secondary,
      },
      '& .menuActions .MuiSvgIcon-root': {
        color: palette?.action?.active,
      },
    },

    colorPrimary: {
      '& .MuiButtonBase-root.menuIcon > .MuiSvgIcon-root, .MuiButtonBase-root.searchIcon > .MuiSvgIcon-root':
        {
          color: palette?.common?.white_states?.main,
        },
      input: {
        color: primaryPalette.contrastText,
        '&::placeholder': {
          opacity: 1,
          color: primaryPalette.contrastText,
        },
      },
      '& .MuiTextField-root': {
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            border: 'none',
            background: palette?.common?.white_states?.focusVisible,
          },
        },
      },
    },

    // default

    colorDefault: {
      input: {
        '&::placeholder': {
          opacity: 1,
          color: palette?.text?.secondary,
        },
      },
      '& .MuiTextField-root': {
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            border: 'none',
            background: palette?.common?.black_states?.selected,
          },
        },
      },
    },

    // primary

    colorSecondary: {
      '& .MuiButtonBase-root.menuIcon > .MuiSvgIcon-root, .MuiButtonBase-root.searchIcon > .MuiSvgIcon-root':
        {
          color: palette?.common?.white_states?.main,
        },
      input: {
        color: primaryPalette.contrastText,
        '&::placeholder': {
          opacity: 1,
          color: primaryPalette.contrastText,
        },
      },
      '& .MuiTextField-root': {
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            border: 'none',
            background: palette?.common?.white_states?.focus,
          },
        },
      },
    },

    // inherit

    colorInherit: {
      input: {
        '&::placeholder': {
          opacity: 1,
          color: palette?.text?.secondary,
        },
      },
      '& .MuiTextField-root': {
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            border: 'none',
            background: palette?.common?.black_states?.hover,
          },
        },
      },
    },

    // transparent

    colorTransparent: {
      backgroundColor: palette?.background?.default,
      boxShadow: shadows?.[0],
      '& .menuActions .MuiSvgIcon-root': {
        color: palette?.icon.secondary,
      },
      borderBottom: `1px solid ${palette?.outlined?.['outlined 2']}`,
      input: {
        '&::placeholder': {
          opacity: 1,
          color: palette?.text?.secondary,
        },
      },
      '& .MuiTextField-root': {
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: palette?.outlined?.outlined,
            background: palette?.common?.black_states?.hover,
          },
          '&.Mui-focused fieldset': {
            borderColor: primaryPalette.main,
          },
          '&:active fieldset': {
            borderColor: primaryPalette.main,
          },
        },
      },
    },
  },
};
