/* eslint-disable max-lines */
import type {} from '@mui/lab/themeAugmentation';
import { Components, CssVarsTheme, Theme } from '@mui/material';

//
//

export const MuiDataGrid: Components<Theme & CssVarsTheme>['MuiTable'] = {
  styleOverrides: {
    root: {
      border: 'none',
      '& .MuiDataGrid-columnHeader--moving': {
        backgroundColor: 'inherit',
      },
      '& .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor': {
        borderTop: 'none',
        borderBottom: '1px solid #E4E4E5',
      },
      '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
        outline: 'none',
      },
      '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
        outline: 'none',
      },
      '& .MuiDataGrid-row:hover': {
        backgroundColor: '#FBFBFB',
      },
      '& .MuiDataGrid-cell': {
        borderTop: 'none',
        borderBottom: '1px solid #E4E4E5',
      },
      '& .MuiDataGrid-columnSeparator:not(.MuiDataGrid-columnSeparator--resizable)': {
        display: 'none',
      },
    },
  },
};
