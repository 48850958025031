import type {} from '@mui/lab/themeAugmentation';
import { Components, CssVarsTheme, Theme } from '@mui/material';

export const MuiMenu: Components<Theme & CssVarsTheme>['MuiMenu'] = {
  styleOverrides: {
    paper: ({ theme }) => ({
      borderRadius: theme.spacing(1.5),
      marginTop: theme.spacing(1.5),
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.palette.outlined?.outlined,
    }),
  },
};
