import type {} from '@mui/lab/themeAugmentation';
import { Components, CssVarsTheme, Theme } from '@mui/material';

export const MuiChip: Components<Theme & CssVarsTheme>['MuiChip'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      minWidth: theme.spacing(7.5),
    }),
  },
};
